import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"

import { Container, Row, Col } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"

import "bootstrap/dist/css/bootstrap.min.css"
import "@fortawesome/fontawesome-svg-core/styles.css"
import "../styles/extra.css"

export default class Datenschutz extends React.Component {
  componentDidMount() {
    function addClass() {
      document.querySelector("header").classList.add("scrolled")
    }
    function removeClass() {
      document.querySelector("header").classList.remove("scrolled")
    }
    window.addEventListener("scroll", function() {
      if (window.scrollY > 90) {
        addClass()
      } else {
        removeClass()
      }
    })
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Paul Cyronek — Datenschutz</title>
          <link rel="canonical" href="https://paulcyronek.de/datenschutz" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>

        <header></header>
        <main className="my-4">
          <Container>
            <Row>
              <Col>
                <h3>Datenschutz</h3>
                <p>
                  Diese Datenschutzerklärung klärt Sie über die Art, den Umfang
                  und Zweck der Verarbeitung von personenbezogenen Daten
                  (nachfolgend kurz „Daten“) im Rahmen der Erbringung unserer
                  Leistungen sowie innerhalb unseres Onlineangebotes und der mit
                  ihm verbundenen Webseiten, Funktionen und Inhalte sowie
                  externen Onlinepräsenzen, wie z.B. unser Social Media Profile
                  auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im
                  Hinblick auf die verwendeten Begrifflichkeiten, wie z.B.
                  „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die
                  Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
                </p>
                <h4>Verantwortlicher</h4>
                <p>
                  Paul Cyronek <br />
                  Anklamer Str. 20 <br />
                  10115 Berlin, Deutschland <br />
                  E-Mail:{" "}
                  <a href="mailto:mail@paulcyronek.de">mail@paulcyronek.de</a>
                </p>
                <h4>Arten der verarbeiteten Daten</h4>
                <ul>
                  <li>
                    Bestandsdaten (z.B., Personen-Stammdaten, Namen oder
                    Adressen).
                  </li>
                  <li>Kontaktdaten (z.B., E-Mail, Telefonnummern).</li>
                  <li>
                    Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).
                  </li>
                  <li>
                    Nutzungsdaten (z.B., besuchte Webseiten, Interesse an
                    Inhalten, Zugriffszeiten).
                  </li>
                  <li>
                    Meta-/Kommunikationsdaten (z.B., Geräte-Informationen,
                    IP-Adressen).
                  </li>
                </ul>
                <h4>Kategorien betroffener Personen</h4>
                <p>
                  Besucher und Nutzer des Onlineangebotes (Nachfolgend
                  bezeichnen wir die betroffenen Personen zusammenfassend auch
                  als „Nutzer“).
                </p>
                <h4>Zweck der Verarbeitung</h4>
                <ul>
                  <li>
                    Zurverfügungstellung des Onlineangebotes, seiner Funktionen
                    und Inhalte.
                  </li>
                  <li>
                    Beantwortung von Kontaktanfragen und Kommunikation mit
                    Nutzern.
                  </li>
                  <li>Sicherheitsmaßnahmen.</li>
                  <li>Reichweitenmessung/Marketing.</li>
                </ul>
                <h4>Verwendete Begrifflichkeiten</h4>
                <p>
                  „Personenbezogene Daten“ sind alle Informationen, die sich auf
                  eine identifizierte oder identifizierbare natürliche Person
                  (im Folgenden „betroffene Person“) beziehen; als
                  identifizierbar wird eine natürliche Person angesehen, die
                  direkt oder indirekt, insbesondere mittels Zuordnung zu einer
                  Kennung wie einem Namen, zu einer Kennnummer, zu
                  Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu
                  einem oder mehreren besonderen Merkmalen identifiziert werden
                  kann, die Ausdruck der physischen, physiologischen,
                  genetischen, psychischen, wirtschaftlichen, kulturellen oder
                  sozialen Identität dieser natürlichen Person sind.
                  <br />
                  <br />
                  „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter
                  Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe
                  im Zusammenhang mit personenbezogenen Daten. Der Begriff
                  reicht weit und umfasst praktisch jeden Umgang mit Daten.
                  <br />
                  <br />
                  „Pseudonymisierung“ die Verarbeitung personenbezogener Daten
                  in einer Weise, dass die personenbezogenen Daten ohne
                  Hinzuziehung zusätzlicher Informationen nicht mehr einer
                  spezifischen betroffenen Person zugeordnet werden können,
                  sofern diese zusätzlichen Informationen gesondert aufbewahrt
                  werden und technischen und organisatorischen Maßnahmen
                  unterliegen, die gewährleisten, dass die personenbezogenen
                  Daten nicht einer identifizierten oder identifizierbaren
                  natürlichen Person zugewiesen werden.
                  <br />
                  <br />
                  „Profiling“ jede Art der automatisierten Verarbeitung
                  personenbezogener Daten, die darin besteht, dass diese
                  personenbezogenen Daten verwendet werden, um bestimmte
                  persönliche Aspekte, die sich auf eine natürliche Person
                  beziehen, zu bewerten, insbesondere um Aspekte bezüglich
                  Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche
                  Vorlieben, Interessen, Zuverlässigkeit, Verhalten,
                  Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu
                  analysieren oder vorherzusagen.
                  <br />
                  <br />
                  Als „Verantwortlicher“ wird die natürliche oder juristische
                  Person, Behörde, Einrichtung oder andere Stelle, die allein
                  oder gemeinsam mit anderen über die Zwecke und Mittel der
                  Verarbeitung von personenbezogenen Daten entscheidet,
                  bezeichnet.
                  <br />
                  <br />
                  „Auftragsverarbeiter“ eine natürliche oder juristische Person,
                  Behörde, Einrichtung oder andere Stelle, die personenbezogene
                  Daten im Auftrag des Verantwortlichen verarbeitet.
                  <br />
                </p>
                <h4>Maßgebliche Rechtsgrundlagen</h4>
                <p>
                  Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die
                  Rechtsgrundlagen unserer Datenverarbeitungen mit. Für Nutzer
                  aus dem Geltungsbereich der Datenschutzgrundverordnung
                  (DSGVO), d.h. der EU und des EWG gilt, sofern die
                  Rechtsgrundlage in der Datenschutzerklärung nicht genannt
                  wird, Folgendes: <br />
                  <br />
                  Die Rechtsgrundlage für die Einholung von Einwilligungen ist
                  Art. 6 Abs. 1 lit. a und Art. 7 DSGVO;
                  <br />
                  Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
                  Leistungen und Durchführung vertraglicher Maßnahmen sowie
                  Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO;
                  <br />
                  Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
                  rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO;
                  <br />
                  Für den Fall, dass lebenswichtige Interessen der betroffenen
                  Person oder einer anderen natürlichen Person eine Verarbeitung
                  personenbezogener Daten erforderlich machen, dient Art. 6 Abs.
                  1 lit. d DSGVO als Rechtsgrundlage.
                  <br />
                  Die Rechtsgrundlage für die erforderliche Verarbeitung zur
                  Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt
                  oder in Ausübung öffentlicher Gewalt erfolgt, die dem
                  Verantwortlichen übertragen wurde ist Art. 6 Abs. 1 lit. e
                  DSGVO. <br />
                  Die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
                  berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. <br />
                  Die Verarbeitung von Daten zu anderen Zwecken als denen, zu
                  denen sie erhoben wurden, bestimmt sich nach den Vorgaben des
                  Art 6 Abs. 4 DSGVO. <br />
                  Die Verarbeitung von besonderen Kategorien von Daten
                  (entsprechend Art. 9 Abs. 1 DSGVO) bestimmt sich nach den
                  Vorgaben des Art. 9 Abs. 2 DSGVO. <br />
                </p>
                <h4>Sicherheitsmaßnahmen</h4>
                <p>
                  Wir treffen nach Maßgabe der gesetzlichen Vorgabenunter
                  Berücksichtigung des Stands der Technik, der
                  Implementierungskosten und der Art, des Umfangs, der Umstände
                  und der Zwecke der Verarbeitung sowie der unterschiedlichen
                  Eintrittswahrscheinlichkeit und Schwere des Risikos für die
                  Rechte und Freiheiten natürlicher Personen, geeignete
                  technische und organisatorische Maßnahmen, um ein dem Risiko
                  angemessenes Schutzniveau zu gewährleisten.
                  <br />
                  <br />
                  Zu den Maßnahmen gehören insbesondere die Sicherung der
                  Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
                  Kontrolle des physischen Zugangs zu den Daten, als auch des
                  sie betreffenden Zugriffs, der Eingabe, Weitergabe, der
                  Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren
                  haben wir Verfahren eingerichtet, die eine Wahrnehmung von
                  Betroffenenrechten, Löschung von Daten und Reaktion auf
                  Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir
                  den Schutz personenbezogener Daten bereits bei der
                  Entwicklung, bzw. Auswahl von Hardware, Software sowie
                  Verfahren, entsprechend dem Prinzip des Datenschutzes durch
                  Technikgestaltung und durch datenschutzfreundliche
                  Voreinstellungen.
                  <br />
                </p>
                <h4>
                  Zusammenarbeit mit Auftragsverarbeitern, gemeinsam
                  Verantwortlichen und Dritten
                </h4>
                <p>
                  Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber
                  anderen Personen und Unternehmen (Auftragsverarbeitern,
                  gemeinsam Verantwortlichen oder Dritten) offenbaren, sie an
                  diese übermitteln oder ihnen sonst Zugriff auf die Daten
                  gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen
                  Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte,
                  wie an Zahlungsdienstleister, zur Vertragserfüllung
                  erforderlich ist), Nutzer eingewilligt haben, eine rechtliche
                  Verpflichtung dies vorsieht oder auf Grundlage unserer
                  berechtigten Interessen (z.B. beim Einsatz von Beauftragten,
                  Webhostern, etc.). <br />
                  <br />
                  Sofern wir Daten anderen Unternehmen unserer
                  Unternehmensgruppe offenbaren, übermitteln oder ihnen sonst
                  den Zugriff gewähren, erfolgt dies insbesondere zu
                  administrativen Zwecken als berechtigtes Interesse und
                  darüberhinausgehend auf einer den gesetzlichen Vorgaben
                  entsprechenden Grundlage.
                  <br />
                </p>
                <h4>Übermittlungen in Drittländer</h4>
                <p>
                  Sofern wir Daten in einem Drittland (d.h. außerhalb der
                  Europäischen Union (EU), des Europäischen Wirtschaftsraums
                  (EWR) oder der Schweizer Eidgenossenschaft) verarbeiten oder
                  dies im Rahmen der Inanspruchnahme von Diensten Dritter oder
                  Offenlegung, bzw. Übermittlung von Daten an andere Personen
                  oder Unternehmen geschieht, erfolgt dies nur, wenn es zur
                  Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage
                  Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung
                  oder auf Grundlage unserer berechtigten Interessen geschieht.
                  Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich
                  erforderlicher Übermittlung, verarbeiten oder lassen wir die
                  Daten nur in Drittländern mit einem anerkannten
                  Datenschutzniveau, zu denen die unter dem "Privacy-Shield"
                  zertifizierten US-Verarbeiter gehören oder auf Grundlage
                  besonderer Garantien, wie z.B. vertraglicher Verpflichtung
                  durch sogenannte Standardschutzklauseln der EU-Kommission, dem
                  Vorliegen von Zertifizierungen oder verbindlichen internen
                  Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO,{" "}
                  <a
                    href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu_de"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Informationsseite der EU-Kommission
                  </a>
                  ).
                </p>
                <h4>Rechte der betroffenen Personen</h4>
                <p>
                  Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
                  betreffende Daten verarbeitet werden und auf Auskunft über
                  diese Daten sowie auf weitere Informationen und Kopie der
                  Daten entsprechend den gesetzlichen Vorgaben.
                  <br />
                  <br />
                  Sie haben entsprechend. den gesetzlichen Vorgaben das Recht,
                  die Vervollständigung der Sie betreffenden Daten oder die
                  Berichtigung der Sie betreffenden unrichtigen Daten zu
                  verlangen.
                  <br />
                  <br />
                  Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu
                  verlangen, dass betreffende Daten unverzüglich gelöscht
                  werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben
                  eine Einschränkung der Verarbeitung der Daten zu verlangen.
                  <br />
                  <br />
                  Sie haben das Recht zu verlangen, dass die Sie betreffenden
                  Daten, die Sie uns bereitgestellt haben nach Maßgabe der
                  gesetzlichen Vorgaben zu erhalten und deren Übermittlung an
                  andere Verantwortliche zu fordern. <br />
                  <br />
                  Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das
                  Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde
                  einzureichen.
                  <br />
                </p>
                <h4>Widerrufsrecht</h4>
                <p>
                  Sie haben das Recht, erteilte Einwilligungen mit Wirkung für
                  die Zukunft zu widerrufen.
                </p>
                <h4>Widerspruchsrecht</h4>
                <p>
                  Sie können der künftigen Verarbeitung der Sie betreffenden
                  Daten nach Maßgabe der gesetzlichen Vorgaben jederzeit
                  widersprechen. Der Widerspruch kann insbesondere gegen die
                  Verarbeitung für Zwecke der Direktwerbung erfolgen.
                </p>
                <h4>Cookies und Widerspruchsrecht bei Direktwerbung</h4>
                <p>
                  Als „Cookies“ werden kleine Dateien bezeichnet, die auf
                  Rechnern der Nutzer gespeichert werden. Innerhalb der Cookies
                  können unterschiedliche Angaben gespeichert werden. Ein Cookie
                  dient primär dazu, die Angaben zu einem Nutzer (bzw. dem Gerät
                  auf dem das Cookie gespeichert ist) während oder auch nach
                  seinem Besuch innerhalb eines Onlineangebotes zu speichern.
                  Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente
                  Cookies“, werden Cookies bezeichnet, die gelöscht werden,
                  nachdem ein Nutzer ein Onlineangebot verlässt und seinen
                  Browser schließt. In einem solchen Cookie kann z.B. der Inhalt
                  eines Warenkorbs in einem Onlineshop oder ein Login-Status
                  gespeichert werden. Als „permanent“ oder „persistent“ werden
                  Cookies bezeichnet, die auch nach dem Schließen des Browsers
                  gespeichert bleiben. So kann z.B. der Login-Status gespeichert
                  werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen.
                  Ebenso können in einem solchen Cookie die Interessen der
                  Nutzer gespeichert werden, die für Reichweitenmessung oder
                  Marketingzwecke verwendet werden. Als „Third-Party-Cookie“
                  werden Cookies bezeichnet, die von anderen Anbietern als dem
                  Verantwortlichen, der das Onlineangebot betreibt, angeboten
                  werden (andernfalls, wenn es nur dessen Cookies sind spricht
                  man von „First-Party Cookies“).
                  <br />
                  <br />
                  Wir können temporäre und permanente Cookies einsetzen und
                  klären hierüber im Rahmen unserer Datenschutzerklärung auf.
                  <br />
                  <br />
                  Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner
                  gespeichert werden, werden sie gebeten die entsprechende
                  Option in den Systemeinstellungen ihres Browsers zu
                  deaktivieren. Gespeicherte Cookies können in den
                  Systemeinstellungen des Browsers gelöscht werden. Der
                  Ausschluss von Cookies kann zu Funktionseinschränkungen dieses
                  Onlineangebotes führen.
                  <br />
                  <br />
                  Ein genereller Widerspruch gegen den Einsatz der zu Zwecken
                  des Onlinemarketing eingesetzten Cookies kann bei einer
                  Vielzahl der Dienste, vor allem im Fall des Trackings, über
                  die US-amerikanische Seite{" "}
                  <a
                    href="http://www.aboutads.info/choices/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.aboutads.info/choices/
                  </a>{" "}
                  oder die EU-Seite{" "}
                  <a
                    href="http://www.youronlinechoices.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.youronlinechoices.com/
                  </a>{" "}
                  erklärt werden. Des Weiteren kann die Speicherung von Cookies
                  mittels deren Abschaltung in den Einstellungen des Browsers
                  erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls
                  nicht alle Funktionen dieses Onlineangebotes genutzt werden
                  können.
                </p>
                <h4>Löschung von Daten</h4>
                <p>
                  Die von uns verarbeiteten Daten werden nach Maßgabe der
                  gesetzlichen Vorgaben gelöscht oder in ihrer Verarbeitung
                  eingeschränkt. Sofern nicht im Rahmen dieser
                  Datenschutzerklärung ausdrücklich angegeben, werden die bei
                  uns gespeicherten Daten gelöscht, sobald sie für ihre
                  Zweckbestimmung nicht mehr erforderlich sind und der Löschung
                  keine gesetzlichen Aufbewahrungspflichten entgegenstehen.{" "}
                  <br />
                  <br />
                  Sofern die Daten nicht gelöscht werden, weil sie für andere
                  und gesetzlich zulässige Zwecke erforderlich sind, wird deren
                  Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und
                  nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten,
                  die aus handels- oder steuerrechtlichen Gründen aufbewahrt
                  werden müssen.
                </p>
                <h4>
                  Änderungen und Aktualisierungen der Datenschutzerklärung
                </h4>
                <p>
                  Wir bitten Sie sich regelmäßig über den Inhalt unserer
                  Datenschutzerklärung zu informieren. Wir passen die
                  Datenschutzerklärung an, sobald die Änderungen der von uns
                  durchgeführten Datenverarbeitungen dies erforderlich machen.
                  Wir informieren Sie, sobald durch die Änderungen eine
                  Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
                  sonstige individuelle Benachrichtigung erforderlich wird.
                </p>
                <h4>Kontaktaufnahme</h4>
                <p>
                  Bei der Kontaktaufnahme mit uns (z.B. per E-Mail, Telefon oder
                  via sozialer Medien) werden die Angaben des Nutzers zur
                  Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art.
                  6 Abs. 1 lit. b. (im Rahmen vertraglicher-/vorvertraglicher
                  Beziehungen), Art. 6 Abs. 1 lit. f. (andere Anfragen) DSGVO
                  verarbeitet.. Die Angaben der Nutzer können in einem
                  Customer-Relationship-Management System ("CRM System") oder
                  vergleichbarer Anfragenorganisation gespeichert werden.
                  <br />
                  <br />
                  Wir löschen die Anfragen, sofern diese nicht mehr erforderlich
                  sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre;
                  Ferner gelten die gesetzlichen Archivierungspflichten.
                </p>
                <p></p>
                <h4>Hosting und E-Mail-Versand</h4>
                <p>
                  Die von uns in Anspruch genommenen Hosting-Leistungen dienen
                  der Zurverfügungstellung der folgenden Leistungen:
                  Infrastruktur- und Plattformdienstleistungen, Rechenkapazität,
                  Speicherplatz und Datenbankdienste, E-Mail-Versand,
                  Sicherheitsleistungen sowie technische Wartungsleistungen, die
                  wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen.{" "}
                  <br />
                  <br />
                  Hierbei verarbeiten wir, bzw. unser Hostinganbieter
                  Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten,
                  Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden,
                  Interessenten und Besuchern dieses Onlineangebotes auf
                  Grundlage unserer berechtigten Interessen an einer effizienten
                  und sicheren Zurverfügungstellung dieses Onlineangebotes gem.
                  Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
                  Auftragsverarbeitungsvertrag).
                </p>
                <h4>Onlinepräsenzen in sozialen Medien</h4>
                <p>
                  Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke
                  und Plattformen, um mit den dort aktiven Kunden, Interessenten
                  und Nutzern kommunizieren und sie dort über unsere Leistungen
                  informieren zu können.
                  <br />
                  <br />
                  Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb
                  des Raumes der Europäischen Union verarbeitet werden können.
                  Hierdurch können sich für die Nutzer Risiken ergeben, weil so
                  z.B. die Durchsetzung der Rechte der Nutzer erschwert werden
                  könnte. Im Hinblick auf US-Anbieter die unter dem
                  Privacy-Shield zertifiziert sind, weisen wir darauf hin, dass
                  sie sich damit verpflichten, die Datenschutzstandards der EU
                  einzuhalten.
                  <br />
                  <br />
                  Ferner werden die Daten der Nutzer im Regelfall für
                  Marktforschungs- und Werbezwecke verarbeitet. So können z.B.
                  aus dem Nutzungsverhalten und sich daraus ergebenden
                  Interessen der Nutzer Nutzungsprofile erstellt werden. Die
                  Nutzungsprofile können wiederum verwendet werden, um z.B.
                  Werbeanzeigen innerhalb und außerhalb der Plattformen zu
                  schalten, die mutmaßlich den Interessen der Nutzer
                  entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf
                  den Rechnern der Nutzer gespeichert, in denen das
                  Nutzungsverhalten und die Interessen der Nutzer gespeichert
                  werden. Ferner können in den Nutzungsprofilen auch Daten
                  unabhängig der von den Nutzern verwendeten Geräte gespeichert
                  werden (insbesondere wenn die Nutzer Mitglieder der jeweiligen
                  Plattformen sind und bei diesen eingeloggt sind).
                  <br />
                  <br />
                  Die Verarbeitung der personenbezogenen Daten der Nutzer
                  erfolgt auf Grundlage unserer berechtigten Interessen an einer
                  effektiven Information der Nutzer und Kommunikation mit den
                  Nutzern gem. Art. 6 Abs. 1 lit. f. DSGVO. Falls die Nutzer von
                  den jeweiligen Anbietern der Plattformen um eine Einwilligung
                  in die vorbeschriebene Datenverarbeitung gebeten werden, ist
                  die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a.,
                  Art. 7 DSGVO.
                  <br />
                  <br />
                  Für eine detaillierte Darstellung der jeweiligen
                  Verarbeitungen und der Widerspruchsmöglichkeiten (Opt-Out),
                  verweisen wir auf die nachfolgend verlinkten Angaben der
                  Anbieter.
                  <br />
                  <br />
                  Auch im Fall von Auskunftsanfragen und der Geltendmachung von
                  Nutzerrechten, weisen wir darauf hin, dass diese am
                  effektivsten bei den Anbietern geltend gemacht werden können.
                  Nur die Anbieter haben jeweils Zugriff auf die Daten der
                  Nutzer und können direkt entsprechende Maßnahmen ergreifen und
                  Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann
                  können Sie sich an uns wenden.
                  <br />
                  <br />
                </p>
                <ul>
                  <li>
                    Facebook, -Seiten, -Gruppen, (Facebook Ireland Ltd., 4 Grand
                    Canal Square, Grand Canal Harbour, Dublin 2, Irland) auf
                    Grundlage einer{" "}
                    <a
                      href="https://www.facebook.com/legal/terms/page_controller_addendum"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Vereinbarung über gemeinsame Verarbeitung
                      personenbezogener Daten
                    </a>
                    <br />
                    Datenschutzerklärung:{" "}
                    <a
                      href="https://www.facebook.com/about/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.facebook.com/about/privacy/
                    </a>
                    , Opt-Out:{" "}
                    <a
                      href="https://www.facebook.com/settings?tab=ads"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.facebook.com/settings?tab=ads
                    </a>{" "}
                    und{" "}
                    <a
                      href="http://www.youronlinechoices.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://www.youronlinechoices.com
                    </a>
                    , Privacy Shield:{" "}
                    <a
                      href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
                    </a>
                    .
                  </li>
                  <li>
                    Google/ YouTube (Google Ireland Limited, Gordon House,
                    Barrow Street, Dublin 4, Irland) <br />
                    Datenschutzerklärung:{" "}
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://policies.google.com/privacy
                    </a>
                    , Opt-Out:{" "}
                    <a
                      href="https://adssettings.google.com/authenticated"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://adssettings.google.com/authenticated
                    </a>
                    , Privacy Shield:{" "}
                    <a
                      href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                    </a>
                    .
                  </li>
                  <li>
                    Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA,
                    94025, USA) <br />
                    Datenschutzerklärung / Opt-Out:{" "}
                    <a
                      href="http://instagram.com/about/legal/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://instagram.com/about/legal/privacy/
                    </a>
                    .
                  </li>
                  <li>
                    LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place,
                    Dublin 2, Irland) <br />
                    Datenschutzerklärung:{" "}
                    <a
                      href="https://www.linkedin.com/legal/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.linkedin.com/legal/privacy-policy
                    </a>
                    , Opt-Out:{" "}
                    <a
                      href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
                    </a>
                    , Privacy Shield:{" "}
                    <a
                      href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active
                    </a>
                    .
                  </li>
                  <li>
                    Soundcloud (SoundCloud Limited, Rheinsberger Str. 76/77,
                    10115 Berlin, Deutschland) <br />
                    Datenschutzerklärung / Opt-Out:{" "}
                    <a
                      href="https://soundcloud.com/pages/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://soundcloud.com/pages/privacy
                    </a>
                    .
                  </li>
                </ul>
                <h4>Einbindung von Diensten und Inhalten Dritter</h4>
                <p>
                  Wir setzen innerhalb unseres Onlineangebotes auf Grundlage
                  unserer berechtigten Interessen (d.h. Interesse an der
                  Analyse, Optimierung und wirtschaftlichem Betrieb unseres
                  Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
                  Inhalts- oder Serviceangebote von Drittanbietern ein, um deren
                  Inhalte und Services, wie z.B. Videos oder Schriftarten
                  einzubinden (nachfolgend einheitlich bezeichnet als
                  “Inhalte”). <br />
                  <br />
                  Dies setzt immer voraus, dass die Drittanbieter dieser
                  Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie ohne die
                  IP-Adresse die Inhalte nicht an deren Browser senden könnten.
                  Die IP-Adresse ist damit für die Darstellung dieser Inhalte
                  erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden,
                  deren jeweilige Anbieter die IP-Adresse lediglich zur
                  Auslieferung der Inhalte verwenden. Drittanbieter können
                  ferner so genannte Pixel-Tags (unsichtbare Grafiken, auch als
                  "Web Beacons" bezeichnet) für statistische oder
                  Marketingzwecke verwenden. Durch die "Pixel-Tags" können
                  Informationen, wie der Besucherverkehr auf den Seiten dieser
                  Website ausgewertet werden. Die pseudonymen Informationen
                  können ferner in Cookies auf dem Gerät der Nutzer gespeichert
                  werden und unter anderem technische Informationen zum Browser
                  und Betriebssystem, verweisende Webseiten, Besuchszeit sowie
                  weitere Angaben zur Nutzung unseres Onlineangebotes enthalten,
                  als auch mit solchen Informationen aus anderen Quellen
                  verbunden werden.
                </p>
                <h4>Youtube</h4>
                <p>
                  Wir binden die Videos der Plattform “YouTube” des Anbieters
                  Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
                  Irland, ein. Datenschutzerklärung:{" "}
                  <a
                    href="https://www.google.com/policies/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.google.com/policies/privacy/
                  </a>
                  , Opt-Out:{" "}
                  <a
                    href="https://adssettings.google.com/authenticated"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://adssettings.google.com/authenticated
                  </a>
                  .
                </p>
                <h4>Vimeo</h4>
                <p>
                  Wir können die Videos der Plattform “Vimeo” des Anbieters
                  Vimeo Inc., Attention: Legal Department, 555 West 18th Street
                  New York, New York 10011, USA, einbinden. Wir weisen darauf
                  hin, dass Vimeo Google Analytics einsetzen kann und verweisen
                  hierzu auf die Datenschutzerklärung (
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://policies.google.com/privacy
                  </a>
                  ) sowie Opt-Out-Möglichkeiten für Google-Analytics (
                  <a
                    href="http://tools.google.com/dlpage/gaoptout?hl=de"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://tools.google.com/dlpage/gaoptout?hl=de
                  </a>
                  ) oder die Einstellungen von Google für die Datennutzung zu
                  Marketingzwecken (
                  <a
                    href="https://adssettings.google.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://adssettings.google.com/
                  </a>
                  ). Datenschutzerklärung:{" "}
                  <a
                    href="https://vimeo.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://vimeo.com/privacy
                  </a>
                  .
                </p>
                <h4>Soundcloud</h4>
                <p>
                  Unsere Audiodateien werden auf der Plattform „Soundcloud“,
                  angeboten von SoundCloud Limited, Rheinsberger Str. 76/77,
                  10115 Berlin, Deutschland gespeichert und werden von dieser
                  Platform aus wiedergegeben.
                  <br />
                  <br />
                  Zu diesem Zweck binden wir sog. Soundcloud-Widgets in unsere
                  Website ein. Dabei handelt es sich um Abspielsoftware, mit der
                  Nutzer die Podcasts abspielen können. Hierbei kann Soundcloud
                  messen, welche Podcasts in welchem Umfang gehört werden und
                  diese Information pseudonym für statistische und
                  betriebswirtschaftliche Zwecke verarbeiten. Hierzu können
                  Cookies in den Browsern der Nuzer gespeichert und zwecks
                  Bildung von Nutzerprofilen, z.B. für Zwecke der Ausgabee von
                  Anzeigen, die den potentiellen Interessen der Nutzer
                  entsprechen, verarbeitet werden. Im Fall von Nutzern, die bei
                  Soundcloud registriert sind, kann Soundcloud die
                  Hörinformationen deren Profilen zuordnen.
                  <br />
                  <br />
                  Die Nutzung erfolgt auf Grundlage unserer berechtigten
                  Interessen, d.h. Interesse an einer sicheren und effizienten
                  Bereitstellung, Analyse sowie Optimierung unseres
                  Audioangebotes gem. Art. 6 Abs. 1 lit. f. DSGVO. <br />
                  <br />
                  Weitere Informationen und Widerspruchsmöglichkeiten finden
                  sich in der Datenschutzerklärung von Soundcloud:{" "}
                  <a
                    href="https://soundcloud.com/pages/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://soundcloud.com/pages/privacy
                  </a>
                  .
                </p>
                <h4>Google Fonts</h4>
                <p>
                  Wir binden die Schriftarten ("Google Fonts") des Anbieters
                  Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
                  Irland, ein. Nach Angaben von Google werden die Daten der
                  Nutzer allein zu Zwecken der Darstellung der Schriftarten im
                  Browser der Nutzer verwendet. Die Einbindung erfolgt auf
                  Grundlage unserer berechtigten Interessen an einer technisch
                  sicheren, wartungsfreien und effizienten Nutzung von
                  Schriftarten, deren einheitlicher Darstellung sowie
                  Berücksichtigung möglicher lizenzrechtlicher Restriktionen für
                  deren Einbindung. Datenschutzerklärung:{" "}
                  <a
                    href="https://www.google.com/policies/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.google.com/policies/privacy/
                  </a>
                  .
                </p>
              </Col>
            </Row>
          </Container>
        </main>
        <footer>
          <Container>
            <Row>
              <Col>
                <div className="text-right py-3">
                  <div className="social-media py-2 mb-1">
                    <a
                      className="mr-1"
                      href="https://facebook.com/paulcyronek"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        mask={faCircle}
                        size="2x"
                        transform="shrink-6"
                      />
                    </a>
                    <a
                      className="ml-2 mr-1"
                      href="https://instagram.com/paulcyronek"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        mask={faCircle}
                        size="2x"
                        transform="shrink-6"
                      />
                    </a>
                    <a
                      className="ml-2"
                      href="https://de.linkedin.com/in/paul-cyronek-4b81a1156"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faLinkedinIn}
                        mask={faCircle}
                        size="2x"
                        transform="shrink-6"
                      />
                    </a>
                  </div>
                  <p className="font-weight-light mb-1">
                    Copyright &copy;{" "}
                    <span id="update-year">{new Date().getFullYear()}</span>,
                    Paul Cyronek
                  </p>
                  <p className="mb-1">
                    <Link className="text-reset" to="/impressum">
                      Impressum
                    </Link>
                    <span className="font-weight-light"> | </span>
                    <Link className="text-reset" to="/datenschutz">
                      Datenschutz
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    )
  }
}
